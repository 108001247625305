import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import Carousel from 'components/Carousel'
import Image from 'components/Image'
import RemarkRenderer from 'components/RemarkRenderer'
import { mediaQuery } from 'utils/style'

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`

const CarouselContainer = styled.div`
  min-height: 30rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: ${({ fullWidth }) => (fullWidth ? 'column' : 'row')};
  ${mediaQuery()({
    media: device => (device === 'mobile' ? 'flex-direction:column;' : '')
  })}
`

const Highlight = styled.div`
  ${({ active }) => (active ? 'position:absolute;' : '')}
  top: 0;
  width: 100%;
  padding-left: 1rem;
`

const Spacer = styled.div`
  width: ${({ length }) => `${length * 100}%`};
  overflow: hidden;
  display: flex;
  visibility: hidden;
`

const Highlights = styled.div`
  position: relative;
  flex: 1;
`

const Gallery = styled.div``

export default function StoryblokGallery ({ data }) {
  const [state, setState] = useState(0)
  const galleryRef = useRef()
  return (
    <Container>
      <h2>{data.headline}</h2>
      <RemarkRenderer ast={data.text?.childMarkdownRemark?.htmlAst} />
      <CarouselContainer fullWidth={data.fullWidth}>
        <Carousel
          onSlide={setState}
          gallery={{ ref: galleryRef }}
          infinite
          hideBackside
          height="30rem"
          media={device =>
            (device === 'mobile' || data.fullWidth
              ? 'width: 100%; height: 30rem;'
              : 'width: 50%; height: 30rem;')
          }
        >
          {data?.slides?.map(slide => (
            <Image
              background={data.fullWidth}
              src={slide.image}
              title={slide.imageTitle}
              alt={slide.imageAlt}
              description={slide.imageDescription}
              objectPosition={slide.objectPosition}
              imageStyle={`object-position: ${slide.objectPosition};`}
            />
          ))}
        </Carousel>
        <Highlights>
          {data.fullWidth && (
            <Spacer length={data.slides?.length}>
              {data.slides?.map(slide => (
                <Highlight hide>
                  <RemarkRenderer
                    ast={slide.description?.childMarkdownRemark?.htmlAst}
                  />
                </Highlight>
              ))}
            </Spacer>
          )}
          <Highlight active={data.fullWidth}>
            <RemarkRenderer
              ast={
                data?.slides?.[state].description?.childMarkdownRemark?.htmlAst
              }
            />
          </Highlight>
        </Highlights>
      </CarouselContainer>
      {data.fullWidth && <Gallery ref={galleryRef} />}
    </Container>
  )
}
